import PropTypes from "prop-types"
import React, { Component } from "react"

class ScrollCTA extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollProgress: null,
    }
    this.Scrolling = this.Scrolling.bind(this)
  }

  Scrolling() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop
    const height =
      document.getElementById("main-article").offsetHeight - window.innerHeight
    const scrolled = (winScroll / height) * 100
    if (height > 0) {
      this.setState({ scrollProgress: scrolled })
    } else {
      this.setState({ scrollProgress: null })
    }
  }

  componentDidMount() {
    try {
      window.addEventListener("scroll", this.Scrolling)
    } catch (oError) {
      console.log(oError)
    }
  }

  componentWillUnmount() {
    try {
      window.removeEventListener("scroll", this.Scrolling)
    } catch (oError) {
      console.log(oError)
    }
  }

  render() {
    const { scrollProgress } = this.state
    const { start, end, children } = this.props
    if (start === null && scrollProgress < end) {
      return <>{children}</>
    } else if (scrollProgress > start && end === null) {
      return <>{children}</>
    } else if (scrollProgress > start && scrollProgress < end) {
      return <>{children}</>
    } else {
      return null
    }
  }
}

ScrollCTA.propTypes = {
  start: PropTypes.number,
  end: PropTypes.number,
  children: PropTypes.node.isRequired,
}

ScrollCTA.defaultProps = {
  start: null,
  end: null,
}

export default ScrollCTA
