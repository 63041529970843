import React from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"
import get from "lodash/get"
import { GatsbyImage } from "gatsby-plugin-image"
import { setCurrentPage } from "../../services/tracking"
import { mostRecentDate, readTimeFromRichText } from "../../modules/helpers"
import { format } from "date-fns"
import { nl } from "date-fns/locale"
import linkBuilder from "../../modules/linkBuilder"

/* Components */
import Layout from "../../components/layout"
import Seo from "../../components/seo/seo"
import SocialMeta from "../../components/social-meta/social-meta"
import StickyNav from "../../components/sticky-nav/sticky-nav"
import BreadcrumbClean from "../../components/breadcrumb-clean/breadcrumb-clean"
import Sponsor from "../../components/sponsor/sponsor"
import CtaArticleIntro from "../../components/cta-article-intro/cta-article-intro"
import CtaExternalLink from "../../components/side-column/cta-external-link/cta-external-link"
import CtaDownload from "../../components/side-column/cta-download/cta-download"
import TableOfContents from "../../components/table-of-contents/table-of-contents"
import RichTextContent from "../../components/rich-text-content/rich-text-content"
import Faq from "../../components/faq/faq"
import RelatedArticles from "../../components/related-articles/related-articles"
import RelatedCategories from "../../components/related-categories/related-categories"
import Author from "../../components/author/author"
import Expert from "../../components/expert/expert"
import SocialShare from "../../components/social-share/socia-share"
import CtaNewsLetterArt from "../../components/side-column/cta-newsletter/cta-newsletter-art"
import Footer from "../../components/footer/footer"
import DisplayTracking from "../../components/tracking/display-tracking"
import ArticleRating from "../../components/article-rating/article-rating"

import { IoMdClose } from "react-icons/io"

/* Styles */
import {
  article as articleClass,
  ArticleWrapper,
  ArticleHead,
  ArticleHero,
  ArticleHeroWrapper,
  StickyCTA,
  StickyCTAContent,
  StickyCTATitle,
  StickyCTAAction,
  title,
  subtitle,
  HeroImage,
  AuthorSponsorContainer,
  SponsorSideBlock,
  SponsorSideBlockContent,
  SponsorDescription,
  SponsorTitle,
  SponsorMeta,
  SponsorImage,
  AuthorImage,
  IntroWrapper,
  IntroImageWrapper,
  ArticleMeta,
  ArticleAndAsideWrapper,
  ArticleBodyMain,
  ArticleBodyAside,
  TocWrapperArticle,
  Intro,
  TocAsideHide,
  CallToActionWrapper,
  CallToActionWrapperInner,
  ImageMetaWrapper,
} from "./article.module.css"
import ScrollProgressBar from "../../components/scroll-progress/scroll-progress"
import SmallSponsor from "../../components/small-sponsor/small-sponsor"
import FooterSponsor from "../../components/footer-sponsor/footer-sponsor"
import CtaProduct from "../../components/side-column/cta-product/cta-product"
import CtaNewsletterComponent from "../../components/cta-newsletter/cta-newsletter"
import MediaImage from "../../components/media-image/media-image"
import ScrollCTA from "../../components/scroll-cta/scroll-cta"
import { trackEvent } from "../../modules/analytics"

class ArticleTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      closedCta: false,
    }
    this.closeCTA = this.closeCTA.bind(this)
  }

  closeCTA() {
    console.log("CLOSING")
    this.setState({ closedCta: true })
  }

  render() {
    const article = get(this.props, "data.contentfulArticle")
    const articleDescription = get(
      article,
      "description.childMarkdownRemark.rawMarkdownBody"
    )
    const desks = get(this.props, "pageContext.desks")
    const deskCategories = get(this.props, "pageContext.deskCategories")
    const relatedCategories = get(this.props, "pageContext.relatedCategories")
    const publisherLogo = get(
      this.props,
      "data.publisherLogo.childImageSharp.gatsbyImageData.images.fallback.src"
    )
    const articleUrl = `https://www.mkbservicedesk.nl${linkBuilder.article(
      article
    )}`
    const mostRecent = mostRecentDate(
      article.publishDateInt,
      article.updateDateInt
    )

    // FormBuilder
    const formList = get(this.props, "pageContext.forms")
    if (formList.length > 0) {
      article.body.references = article.body.references.map((reference) => {
        if (
          reference.__typename === "ContentfulForm" &&
          reference.formReference.selectedForm
        ) {
          const contentfulForm = formList.find(
            (form) => form?.id === reference.formReference.selectedForm
          )
          return { ...reference, form: contentfulForm }
        }
        return reference
      })
    }

    setCurrentPage({
      title: article.title,
      contentfulId: article.contentful_id,
      path: linkBuilder.article(article),
      url: articleUrl,
    })

    const ldJson = {
      "@context": "http://schema.org",
      "@type": "NewsArticle",
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": articleUrl,
      },
      headline: article.headline || article.title,
      inLanguage: "nl",
      datePublished: mostRecent,
      publisher: {
        "@type": "Organization",
        name: "MKB Servicedesk",
        logo: {
          "@type": "ImageObject",
          url: `https://www.mkbservicedesk.nl${publisherLogo}`,
          height: "60",
          width: "537",
        },
      },
      description: article.metaDescription || articleDescription,
    }

    if (
      article.updateDateInt &&
      article.publishDateInt !== article.updateDateInt
    )
      ldJson.dateModified = article.updateDateInt
    if (article.image?.gatsbyImageData?.images?.fallback?.src)
      ldJson.image = `https:${article.image.gatsbyImageData?.images?.fallback?.src}`
    if (article.author && article.author.name) {
      ldJson.author = {
        "@type": "Person",
        name: article.author.name,
        url: `https://www.mkbservicedesk.nl${linkBuilder.author(
          article.author
        )}`,
      }
      if (article.author.image)
        ldJson.author.image = `https:${article.author.image.gatsbyImageData?.images?.fallback?.src}`
    }

    let ldJsonOrg = {
      "@context": "https://schema.org",
      "@type": "Organization",
      address: {
        "@type": "PostalAddress",
        addressLocality: "Tilburg, Nederland",
        postalCode: "5037 AA",
        streetAddress: "Reitseplein 1",
      },
      email: "redactie@mkbservicedesk.nl",
      employees: [
        {
          "@type": "Person",
          name: "Jelle Bos",
        },
        {
          "@type": "Person",
          name: "Job Jansen",
        },
        {
          "@type": "Person",
          name: "Peter de Visser",
        },
      ],
      name: "MKB Servicedesk",
      telephone: "088 - 652 00 55",
    }

    // Limit to 8 articles with a maximum updateDate of 2 year ago
    let relatedArticles = []
    if (article.relatedArticles && article.relatedArticles.length) {
      const date = new Date()
      date.setFullYear(date.getFullYear() - 2)
      relatedArticles = article.relatedArticles
        .filter((relatedArticle) => new Date(relatedArticle.updateDate) > date)
        .sort((a, b) => new Date(b.updateDate) - new Date(a.updateDate))
        .slice(0, 8)
    }

    return (
      <Layout location={this.props.location}>
        <ScrollProgressBar />
        <Seo
          noIndex={article.noIndex || false}
          title={article.pageTitle || article.title}
          description={article.metaDescription || articleDescription}
          canonicalUrl={article.canonicalUrl || articleUrl}
        />
        <SocialMeta
          title={article.socialTitle || article.title}
          description={article.socialDescription || articleDescription}
          image={
            article.image &&
            article.image.gatsbyImageData &&
            article.image.gatsbyImageData.images.fallback.src
          }
          url={linkBuilder.article(article)}
        />
        <Helmet>
          <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
          <script type="application/ld+json">
            {JSON.stringify(ldJsonOrg)}
          </script>
        </Helmet>
        <StickyNav desks={desks} />

        {/* STICKY SIDE SPONSOR (DESKTOP ONLY) */}
        {article.sponsor && (
          <div className={SponsorSideBlock}>
            <div className={SponsorSideBlockContent}>
              <p className={SponsorTitle}>Mogelijk gemaakt door:</p>
              {article.sponsor.image && (
                <a
                  id="sponsor"
                  href={article.sponsor.url}
                  rel="sponsored noopener noreferrer"
                  target="_blank"
                >
                  <MediaImage
                    image={article.sponsor.image}
                    alt={article.sponsor.name}
                  />
                </a>
              )}
              {article.sponsor.description && (
                <p className={SponsorDescription}>
                  {
                    article.sponsor.description.childMarkdownRemark
                      .rawMarkdownBody
                  }
                </p>
              )}
            </div>
          </div>
        )}
        {/* STICKY BOTTOM CTA (MOBILE ONLY) */}
        {article.callToAction && !this.state.closedCta && (
          <ScrollCTA start={30} end={70}>
            <div className={StickyCTA}>
              <div className={StickyCTAAction} onClick={this.closeCTA}>
                <IoMdClose size={32} />
              </div>
              <div className={StickyCTAContent}>
                {article.sponsor && (
                  <span>In samenwerking met: {article.sponsor.name}</span>
                )}

                {article.callToAction[0].title && (
                  <Link
                    to={linkBuilder.dynamic(article.callToAction[0])}
                    onClick={() => {
                      trackEvent("cta_mobile_fixed_footer")
                    }}
                  >
                    <span className={StickyCTATitle}>
                      {article.callToAction[0].title}
                    </span>
                  </Link>
                )}
              </div>
            </div>
          </ScrollCTA>
        )}
        <DisplayTracking
          contentfulId={article.contentful_id}
          contentTitle={article.title}
          contentType="article"
          displayPosition="page"
          displayPageContentfulId={article.contentful_id}
          displayPageTitle={article.title}
          displayPagePath={linkBuilder.article(article)}
        >
          <main>
            <div className="container">
              <div className={ArticleHeroWrapper}>
                <div className={ArticleHero}>
                  <nav>
                    {article.categories && article.categories.length > 0 ? (
                      <BreadcrumbClean
                        article={article}
                        desk={article.categories[0].desk}
                        desks={desks}
                        deskCategory={article.categories[0]}
                        deskCategories={deskCategories}
                        theme="light"
                      />
                    ) : (
                      <BreadcrumbClean article={article} theme="light" />
                    )}
                  </nav>
                  <div className={ArticleHead}>
                    <header>
                      <h1 className={title}>{article.title}</h1>
                      <p className={subtitle}>{article.subtitle}</p>
                    </header>
                    <div className={ImageMetaWrapper}>
                      <aside className="articleHeadAside">
                        <div className={ArticleMeta}>
                          <p>
                            {format(new Date(mostRecent), "dd MMMM yyyy", {
                              locale: nl,
                            })}{" "}
                            – Leestijd:{" "}
                            {readTimeFromRichText(article.body?.raw)} min.
                          </p>

                          <div className={AuthorSponsorContainer}>
                            {article.author && (
                              <div className={SponsorMeta}>
                                <p>
                                  Geschreven door:{" "}
                                  {article.author.image && (
                                    <MediaImage
                                      image={article.author.image}
                                      alt={article.author.name}
                                      className={AuthorImage}
                                    />
                                  )}
                                  <Link
                                    rel="nofollow"
                                    to={linkBuilder.author(article.author)}
                                  >
                                    {article.author.name}
                                  </Link>
                                </p>
                              </div>
                            )}
                            {/* TODO: Create component for link tracking */}
                            {article.sponsor && (
                              <div
                                className={SponsorMeta}
                                ref={this.sponsorRef}
                              >
                                <p>
                                  In samenwerking met:{" "}
                                  <a
                                    id="sponsor"
                                    href={article.sponsor.url}
                                    rel="sponsored noopener noreferrer"
                                    target="_blank"
                                  >
                                    {article.sponsor.name}
                                  </a>
                                  {/* {article.sponsor.image && (
                                    <a
                                      id="sponsor"
                                      href={article.sponsor.url}
                                      rel="sponsored noopener noreferrer"
                                      target="_blank"
                                    >
                                      <MediaImage
                                        className={SponsorImage}
                                        image={article.sponsor.image}
                                        alt={article.sponsor.name}
                                      />
                                    </a>
                                  )} */}
                                </p>
                              </div>
                            )}
                          </div>

                          {/* {article.expert && (
                            <span>
                              Expert:{" "}
                              <Link
                                rel="nofollow"
                                to={linkBuilder.expert(article.expert)}
                              >
                                {article.expert.name}
                              </Link>
                            </span>
                          )} */}

                          {/* <SocialShare
                          title={article.socialTitle || article.title}
                          description={
                            article.socialDescription || articleDescription
                          }
                          url={articleUrl}
                        /> */}
                          {/* {article.sponsor && (
                            <SmallSponsor sponsor={article.sponsor} />
                          )} */}
                        </div>
                      </aside>
                    </div>{" "}
                  </div>
                </div>
              </div>
              <div className={ArticleWrapper}>
                <article
                  id="main-article"
                  className={`${articleClass} clearfix`}
                >
                  <div class={IntroImageWrapper}>
                    {articleDescription && (
                      <div class={IntroWrapper}>
                        <p className={Intro}>{articleDescription}</p>
                      </div>
                    )}

                    {article.image && (
                      <div className={HeroImage}>
                        <GatsbyImage
                          loading="eager"
                          image={article.image.gatsbyImageData}
                          alt={article.image.title ? article.image.title : ""}
                        />
                      </div>
                    )}
                  </div>
                  <div className={ArticleAndAsideWrapper}>
                    <div className={ArticleBodyMain}>
                      {(article?.newsletterCta ?? true) && (
                        <CtaNewsletterComponent
                          title="Altijd op de hoogte van het laatste ondernemersnieuws?"
                          description=""
                        />
                      )}

                      {article.body?.raw && (
                        <div className={TocWrapperArticle}>
                          <TableOfContents body={article.body.raw} />
                        </div>
                      )}

                      {article.callToAction && (
                        <CtaArticleIntro
                          callToActions={article.callToAction}
                          displayPagePath={linkBuilder.article(article)}
                          displayPageContentfulId={article.contentful_id}
                          displayPageTitle={article.title}
                          displayPosition="topOfArticle"
                        />
                      )}

                      {article.body && (
                        <RichTextContent
                          content={article.body}
                          pageContentfulId={article.contentful_id}
                          pageTitle={article.title}
                          displayPagePath={linkBuilder.article(article)}
                        ></RichTextContent>
                      )}

                      {article.faq && (
                        <Faq faq={article.faq} visible={article.faqVisible} />
                      )}

                      {article.sponsor && (
                        <FooterSponsor sponsor={article.sponsor} />
                      )}
                      <ArticleRating contentfulId={article.contentful_id} />

                      {article.author && <Author author={article.author} />}
                      {article.expert && <Expert expert={article.expert} />}
                    </div>

                    <aside className={ArticleBodyAside}>
                      <div className="ArticleBodyAsideInner">
                        <div className={TocAsideHide}>
                          {article.body?.raw && (
                            <TableOfContents body={article.body.raw} />
                          )}
                        </div>

                        {article.callToAction &&
                          article.callToAction.length > 0 &&
                          article.callToAction.map((cta, index) => {
                            if (cta.__typename === "ContentfulDownload") {
                              return (
                                <CtaDownload
                                  key={cta.contentful_id}
                                  position={index + 1}
                                  cta={cta}
                                  displayPagePath={linkBuilder.article(article)}
                                  displayPageTitle={article.title}
                                  displayPageContentfulId={
                                    article.contentful_id
                                  }
                                  displayPosition="sideColumn"
                                />
                              )
                            } else if (
                              cta.__typename === "ContentfulProductBeta"
                            ) {
                              return (
                                <CtaProduct
                                  key={cta.contentful_id}
                                  cta={cta}
                                  displayPagePath={linkBuilder.article(article)}
                                  displayPageTitle={article.title}
                                  displayPageContentfulId={
                                    article.contentful_id
                                  }
                                  displayPosition="sideColumn"
                                />
                              )
                            } else if (
                              cta.__typename === "ContentfulExternalLink"
                            ) {
                              return (
                                <CtaExternalLink
                                  cta={cta}
                                  position={index + 1}
                                  key={cta.contentful_id}
                                  displayPagePath={linkBuilder.article(article)}
                                  displayPageTitle={article.title}
                                  displayPageContentfulId={
                                    article.contentful_id
                                  }
                                  displayPosition="sideColumn"
                                />
                              )
                            }
                          })}
                      </div>
                    </aside>
                  </div>
                </article>

                <RelatedArticles articles={relatedArticles}></RelatedArticles>

                <div className={CallToActionWrapper}>
                  <div className={CallToActionWrapperInner}>
                    <div className="newsletter">
                      <CtaNewsLetterArt
                        title={"Elke week tips en inspiratie in je inbox!"}
                        description={
                          "Schrijf je in voor onze nieuwsbrief en ontvang elke week een mail met praktische informatie die je bedrijf écht vooruit helpt."
                        }
                      />
                    </div>

                    {/* {article.callToAction &&
                    article.callToAction.length > 0 &&
                    article.callToAction.splice(0, 1).map((cta, index) => {
                      if (cta.__typename === "ContentfulExternalLink")
                        return (
                          <CtaExternalLink
                            position={index + 1}
                            key={`cta-${index}`}
                            cta={cta}
                          />
                        )
                      else
                        return (
                          <CtaDownload
                            position={index + 1}
                            cta={cta}
                            displayPagePath={linkBuilder.article(article)}
                            displayPageTitle={article.title}
                            displayPageContentfulId={article.contentful_id}
                          />
                        )
                    })} */}
                  </div>
                </div>

                {relatedCategories && relatedCategories.length && (
                  <RelatedCategories categories={relatedCategories} />
                )}
              </div>
            </div>
          </main>
        </DisplayTracking>
        <Footer />
      </Layout>
    )
  }
}

export default ArticleTemplate

export const pageQuery = graphql`
  query ArticleBySlug($id: String!) {
    site {
      siteMetadata {
        title
      }
    }
    publisherLogo: file(relativePath: { eq: "logo-mkbservicedesk.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FIXED, width: 537, height: 60, quality: 100)
      }
    }
    contentfulArticle(id: { eq: $id }) {
      id
      contentful_id
      title
      subtitle
      slug
      slug2
      legacyId
      noIndex
      newsletterCta
      publishDate(formatString: "DD MMMM YYYY", locale: "nl")
      publishDateInt: publishDate(formatString: "YYYY-MM-DD")
      updateDate(formatString: "DD MMMM YYYY", locale: "nl")
      updateDateInt: updateDate(formatString: "YYYY-MM-DD")
      pageTitle
      metaDescription
      canonicalUrl
      headline
      socialTitle
      socialDescription
      relatedArticles {
        id
        publishDate
        updateDate
        title
        slug
        slug2
        categories {
          name
          slug
          slug2
          legacyId
          priority
          desk {
            name
            slug
            slug2
            legacyId
          }
        }
        legacyId
        priority
        image {
          title
          gatsbyImageData(layout: CONSTRAINED, width: 400, quality: 100)
        }
      }
      image {
        title
        gatsbyImageData(
          layout: CONSTRAINED
          width: 728
          quality: 100
          breakpoints: [350, 728]
        )
      }
      faqVisible
      faq {
        question
        answer {
          raw
        }
      }
      body {
        raw
        references {
          __typename
          ... on ContentfulProductBeta {
            contentful_id
            productType
            name
            slug
          }
          ... on ContentfulInlineImage {
            contentful_id
            title
            linkReference {
              __typename
              ... on ContentfulExternalLink {
                contentful_id
                url
              }
              ... on ContentfulArticle {
                contentful_id
                id
                legacyId
                title
                slug
                slug2
                categories {
                  slug2
                  desk {
                    slug2
                  }
                }
              }
              ... on ContentfulCampaign {
                contentful_id
                name
                slug
              }
              ... on ContentfulDesk {
                contentful_id
                legacyId
                name
                slug
                slug2
              }
              ... on ContentfulDeskCategory {
                contentful_id
                legacyId
                name
                slug
                slug2
              }
              ... on ContentfulDownload {
                contentful_id
                id
                downloadType
                slug
                title
              }
              ... on ContentfulProductBeta {
                contentful_id
                productType
                name
                slug
              }
              ... on ContentfulPage {
                contentful_id
                name
                slug
              }
            }
            image {
              mimeType
              file {
                url
              }
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1400
                height: 400
                quality: 100
              )
            }
            imageMobile {
              mimeType
              file {
                url
              }
              gatsbyImageData(
                layout: CONSTRAINED
                width: 1400
                height: 400
                quality: 100
              )
            }
          }
          ... on ContentfulAsset {
            contentful_id
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 728
              quality: 100
              breakpoints: [350, 728]
            )
          }
          ... on ContentfulArticle {
            contentful_id
            id
            legacyId
            title
            slug
            slug2
            categories {
              slug2
              desk {
                slug2
              }
            }
          }
          ... on ContentfulCampaign {
            contentful_id
            name
            slug
          }
          ... on ContentfulDesk {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDeskCategory {
            contentful_id
            legacyId
            name
            slug
            slug2
          }
          ... on ContentfulDownload {
            contentful_id
            id
            downloadType
            slug
            asset {
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
            formFields
            title
            sponsor {
              name
            }
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 690
                quality: 100
                breakpoints: [350, 690]
              )
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          ... on ContentfulExternalLink {
            contentful_id
            url
          }
          ... on ContentfulOutgrow {
            contentful_id
            outgrowId
          }
          ... on ContentfulSponsor {
            contentful_id
            name
            url
          }
          ... on ContentfulSpotify {
            contentful_id
            title
            link
          }
          ... on ContentfulTable {
            contentful_id
            tableData {
              id
              headerColumn
              headerRow
              tableData
            }
          }
          ... on ContentfulExplanation {
            contentful_id
            title
            body {
              raw
              references {
                __typename
                ... on ContentfulArticle {
                  contentful_id
                  id
                  legacyId
                  title
                  slug
                  slug2
                  categories {
                    slug2
                    desk {
                      slug2
                    }
                  }
                }
                ... on ContentfulCampaign {
                  contentful_id
                  name
                  slug
                }
                ... on ContentfulDesk {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulDeskCategory {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulDownload {
                  contentful_id
                  id
                  downloadType
                  slug
                  title
                }
              }
            }
          }

          ... on ContentfulQuote {
            contentful_id
            title
            body {
              raw
              references {
                __typename
                ... on ContentfulArticle {
                  contentful_id
                  id
                  legacyId
                  title
                  slug
                  slug2
                  categories {
                    slug2
                    desk {
                      slug2
                    }
                  }
                }
                ... on ContentfulCampaign {
                  contentful_id
                  name
                  slug
                }
                ... on ContentfulDesk {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulDeskCategory {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulDownload {
                  contentful_id
                  id
                  downloadType
                  slug
                  title
                }
              }
            }
          }
          ... on ContentfulVideo {
            contentful_id
            url
          }
          ... on ContentfulVisme {
            contentful_id
            vismeId
            title
          }
          ... on ContentfulForm {
            contentful_id
            formReference {
              selectedForm
            }
          }
        }
      }
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      categories {
        name
        slug
        slug2
        legacyId
        priority
        desk {
          name
          slug
          slug2
          legacyId
        }
      }
      callToAction {
        __typename
        ... on ContentfulProductBeta {
          id
          name
          slug
          productType
          callToActionTitle
          callToActionButtonText
          callToActionImage {
            title
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
          }
          callToActionDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
        }
        ... on ContentfulDownload {
          id
          showSidebarCtaImage
          contentful_id
          downloadType
          title
          slug
          image {
            title
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
            gatsbyImageData(
              layout: CONSTRAINED
              width: 690
              quality: 100
              breakpoints: [350, 690]
            )
          }
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          sponsor {
            name
            url
            description {
              childMarkdownRemark {
                rawMarkdownBody
              }
            }
            shortDescription {
              raw
              references {
                __typename
                ... on ContentfulArticle {
                  contentful_id
                  id
                  legacyId
                  title
                  slug
                  slug2
                  categories {
                    slug2
                    desk {
                      slug2
                    }
                  }
                }
                ... on ContentfulCampaign {
                  contentful_id
                  name
                  slug
                }
                ... on ContentfulDesk {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulProductBeta {
                  contentful_id
                  productType
                  name
                  slug
                }
                ... on ContentfulDeskCategory {
                  contentful_id
                  legacyId
                  name
                  slug
                  slug2
                }
                ... on ContentfulDownload {
                  contentful_id
                  id
                  downloadType
                  slug
                  title
                }
                ... on ContentfulExternalLink {
                  contentful_id
                  url
                }
                ... on ContentfulSponsor {
                  contentful_id
                  name
                  url
                }
              }
            }
            image {
              gatsbyImageData(
                layout: CONSTRAINED
                width: 250
                quality: 100
                breakpoints: [250, 500]
              )
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          formFields
          asset {
            id
            file {
              url
              contentType
            }
          }
        }
        ... on ContentfulExternalLink {
          id
          contentful_id
          title
          url
          linkText
          showSidebarCtaImage
          description {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          image {
            title
            gatsbyImageData(
              layout: CONSTRAINED
              width: 250
              quality: 100
              breakpoints: [250, 500]
            )
            file {
              url
              details {
                image {
                  height
                  width
                }
              }
            }
          }
        }
      }
      sponsor {
        contentful_id
        id
        name
        url
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        shortDescription {
          raw
          references {
            __typename
            ... on ContentfulArticle {
              contentful_id
              id
              legacyId
              title
              slug
              slug2
              categories {
                slug2
                desk {
                  slug2
                }
              }
            }
            ... on ContentfulCampaign {
              contentful_id
              name
              slug
            }
            ... on ContentfulDesk {
              contentful_id
              legacyId
              name
              slug
              slug2
            }
            ... on ContentfulProductBeta {
              contentful_id
              productType
              name
              slug
            }
            ... on ContentfulDeskCategory {
              contentful_id
              legacyId
              name
              slug
              slug2
            }
            ... on ContentfulDownload {
              contentful_id
              id
              downloadType
              slug
              title
            }
            ... on ContentfulExternalLink {
              contentful_id
              url
            }
            ... on ContentfulSponsor {
              contentful_id
              name
              url
            }
          }
        }
        image {
          title
          gatsbyImageData(
            layout: CONSTRAINED
            width: 250
            quality: 100
            breakpoints: [250, 500]
          )
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
        }
      }
      author {
        name
        slug
        authorPage
        image {
          gatsbyImageData(layout: FIXED, width: 100, height: 100)
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
        communityProfileUrl
      }
      expert {
        name
        slug
        expertPage
        image {
          gatsbyImageData(
            layout: CONSTRAINED
            width: 100
            quality: 100
            breakpoints: [100, 200]
          )
        }
        description {
          childMarkdownRemark {
            rawMarkdownBody
          }
        }
      }
    }
  }
`
